
    import { defineComponent } from '@vue/runtime-core';
    import { updateSubject } from '@/Study/helpers';

    type data = {
        share: boolean | null,
        read: boolean,
        agreed: boolean,
        valid: { share: boolean, read: boolean, agreed: boolean }
    }

    export default defineComponent({
        data: (): data => ({
            share: null,
            read: false,
            agreed: false,
            valid: { share: true, read: true, agreed: true }
        }),
        watch: {
            read(){
                this.valid.read = true;
            },
            share(){
                this.valid.share = true;
            },
            agreed(){
                this.valid.agreed = true;
            }
        },
        methods: {
            next() {
                const { share, read, agreed, valid } = this;
                if(share === null)
                    valid.share = false;
                if(!read)
                   valid.read = false;
                if(!agreed)
                    valid.agreed = false;
                if(share === null || !read || !agreed){
                    const elem = !valid.share ? 'share' : 'readAgreed';
                    const elemPos = (document.querySelector(`#${elem}`) as HTMLElement).offsetTop;
                    return document.querySelector('main')?.scrollTo({ top: (elemPos as number) - 28, behavior: 'smooth' });
                }

                const updates = { agreedAt: Date.now(), agreedToShare: share };
                updateSubject(updates);
                this.$router.push(`demographics`);
            },
        }
    });
